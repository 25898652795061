import Button from '@material-ui/core/Button';
import { Puff } from 'react-loader-spinner';

const ProgressStepperNextBack = ({
  stepsLabels,
  activeStep,
  nextDisabled,
  onSubmit,
  onNextStep,
  onBackStep,
  loader
}: any) => {
  return (
    <div className="progress-stepper-next-back-section">
      <div className="progress-stepper-next-back">
        <Button
          disabled={activeStep === 0}
          onClick={onBackStep}
          variant="contained"
          color="primary"
          className="back-button"
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={nextDisabled || loader}
          id={loader ? 'loader' : ''}
          className={
            activeStep === stepsLabels.length - 1
              ? 'next-button next-button-create'
              : 'next-button'
          }
          onClick={
            activeStep === stepsLabels.length - 1 ? onSubmit : onNextStep
          }
        >
          {activeStep === stepsLabels.length - 1 ? (
            !loader ? (
              'Create Account'
            ) : (
              <Puff color="#1E3079" height={25} width={25} visible={loader} />
            )
          ) : !loader ? (
            'Next'
          ) : (
            <Puff color="#1E3079" height={25} width={25} visible={loader} />
          )}
        </Button>
      </div>
    </div>
  );
};

export default ProgressStepperNextBack;
