import { useRef, useState } from 'react';

const CopyLinkToClipboard = ({ url, text }: { url: string; text: string }) => {
  const [copySuccess, setCopySuccess] = useState('(Click to Copy)');
  const linkRef = useRef(null);

  const copyToClipboard = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigator.clipboard.writeText(e.currentTarget.href);
    setCopySuccess('- Copied!');
  };

  return (
    <div>
      <a ref={linkRef} href={url} onClick={copyToClipboard}>
        <b>{text}</b>
      </a>
      &nbsp;{copySuccess}
    </div>
  );
};

export default CopyLinkToClipboard;
