import { useState, useContext, useEffect } from 'react';
import { Routes } from 'common/enums';
import { Link } from 'react-router-dom';
import { fallDown as Menu } from 'react-burger-menu';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppContext from 'components/AppContext';
import AcmeImage from '../../common/assets/acme.png';
import './header.scss';

export const BLANK = '';
export const SETTINGS = 'Settings';
export const CLOSE = 'Close';
export const WORKFLOW_SETTINGS = 'Workflow Settings';
export const WORKFLOW_ID = 'Workflow ID';
export const WORKFLOW_ID_ERROR = `${WORKFLOW_ID} is required`;
export const ID_METRICS_VERSION = 'ID Metrics version';
export const ID_METRICS_VERSION_ERROR = `${ID_METRICS_VERSION} is required`;
export const ADDRESS_VERIFICATION_IS_ENABLED =
  'Address verification is enabled';
export const ADDRESS_VERIFICATION_IS_DISABLED =
  'Address verification is disabled';
export const DOCUMENT_VERIFICATION_IS_ENABLED =
  'Document verification is enabled';
export const DOCUMENT_VERIFICATION_IS_DISABLED =
  'Document verification is disabled';
export const REGISTER_ACCOUNT_WITH_SSI_IS_DISABLED =
  'Register account with SSI is disabled';
export const REGISTER_ACCOUNT_WITH_SSI_IS_ENABLED =
  'Register account with SSI is enabled';
export const USERNAME_ERROR = 'Username is required';
export const PASSWORD_ERROR = 'Password is required';
export const LOGIN = 'Login';
export const VC_CUSTOM_TEXT =
  'Scan with your wallet to login with your verifiable credential';

const Header = () => {
  const [closed, setClosed] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const myContext = useContext(AppContext);
  const configGroup = {
    workflowId: myContext?.formKey,
    idmetricVersion: myContext?.idMetricVersion,
    addressVerification: myContext?.addressVerification,
    documentVerification: myContext?.documentVerification,
    registerAccountWithSSI: myContext?.registerAccountWithSSI,
    saveSettings: myContext.saveSettings
  };
  const handleOpen = () => {
    setOpenMenu(true);
    setClosed(false);
  };
  const handleClose = () => {
    setClosed(true);
    setOpenMenu(false);
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo-container">
          <h1 className="header-logo">
            <Link to={Routes.DEFAULT}>
              <img src={AcmeImage} alt={'AcmeLogo'} />
            </Link>
          </h1>
        </div>
        <div className="menu-container">
          <div className="top-menu-middle-section">
            <nav className="top-menu-nav">
              <ul className="top-menu">
                <li className="menu-item">
                  <Link to={Routes.DEFAULT}>About Us</Link>
                </li>
                <li className="menu-item">
                  <Link to={Routes.DEFAULT}>FAQs</Link>
                </li>
                <li className="menu-item">
                  <Link to={Routes.DEFAULT}>Cardholder Agreement</Link>
                </li>
                <li className="menu-item">
                  {OpenSettingScreen(configGroup, false)}
                </li>
              </ul>
            </nav>
            <nav className="side-menu-nav">
              <ul className="top-menu">
                <li className="menu-item contact-us">
                  <Link className="contact-us-button" to={Routes.DEFAULT}>
                    Contact Us
                  </Link>
                </li>
                <li className="menu-item login">
                  <Link className="login-button" to={Routes.LOGIN}>
                    Login<span className="arrow">&rarr;</span>
                  </Link>
                </li>
              </ul>
            </nav>
            <Menu
              className={!openMenu ? 'et_mobile_menu hide' : 'et_mobile_menu'}
              outerContainerId={'main-container'}
              isOpen={!closed}
              onClose={handleClose}
              onOpen={handleOpen}
            >
              <Link to={Routes.DEFAULT} onClick={() => setClosed(true)}>
                About Us
              </Link>
              <Link to={Routes.DEFAULT} onClick={() => setClosed(true)}>
                FAQs
              </Link>
              <Link to={Routes.DEFAULT} onClick={() => setClosed(true)}>
                Cardholder Agreement
              </Link>
              <Link to={Routes.LOGIN} onClick={() => setClosed(true)}>
                Login
              </Link>
              <Link to={Routes.DEFAULT} onClick={() => setClosed(true)}>
                Contact Us
              </Link>
              <Link to={''} onClick={() => setClosed(true)}>
                {OpenSettingScreen(configGroup, true)}
              </Link>
            </Menu>
          </div>
        </div>
      </div>
    </header>
  );
};

const OpenSettingScreen = (
  configGroup: {
    workflowId: any;
    idmetricVersion: any;
    addressVerification: any;
    documentVerification: any;
    registerAccountWithSSI: any;
    saveSettings: any;
  },
  isMobile: boolean
) => {
  const [open, setOpen] = useState(false);
  const [workflowId, setWorkflowId] = useState(configGroup.workflowId);
  const [idmetricVersion, setIdmetricVersion] = useState(
    configGroup.idmetricVersion
  );
  const [addressVerification, setAddressVerification] = useState(
    configGroup.addressVerification
  );
  const [documentVerification, setDocumentVerification] = useState(
    configGroup.documentVerification
  );
  const [registerAccountWithSSI, setRegisterAccountWithSSI] = useState(
    configGroup.registerAccountWithSSI
  );

  useEffect(() => {
    setWorkflowId(configGroup.workflowId);
    setIdmetricVersion(configGroup.idmetricVersion);
    setAddressVerification(configGroup.addressVerification);
    setDocumentVerification(configGroup.documentVerification);
    setRegisterAccountWithSSI(configGroup.registerAccountWithSSI);
  }, [
    configGroup.workflowId,
    configGroup.idmetricVersion,
    configGroup.registerAccountWithSSI,
    configGroup.addressVerification,
    configGroup.documentVerification
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    configGroup?.saveSettings({
      workflowId,
      idmetricVersion,
      addressVerification,
      documentVerification,
      registerAccountWithSSI
    });
    setOpen(false);
  };

  /** FIX-Nav bar vanished issue after opening keyboard on IOS device */
  const onBlurInput = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="workflow-setting-screen">
      <div className="setting" onClick={() => handleClickOpen()}>
        {SETTINGS}
      </div>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        className={'workflow-setting-screen-description'}
      >
        <DialogTitle>{WORKFLOW_SETTINGS}</DialogTitle>
        <DialogContent>
          <div className="text-margin">
            <TextField
              required
              id="filled-required"
              label={WORKFLOW_ID}
              value={workflowId}
              error={workflowId === BLANK}
              helperText={workflowId === BLANK ? WORKFLOW_ID_ERROR : BLANK}
              onChange={(e) => setWorkflowId(e?.target?.value)}
              onBlur={onBlurInput}
              variant="filled"
            />
          </div>
          <div className="text-margin">
            <TextField
              required
              id="filled-required"
              label={ID_METRICS_VERSION}
              value={idmetricVersion}
              error={idmetricVersion === BLANK}
              helperText={
                idmetricVersion === BLANK ? ID_METRICS_VERSION_ERROR : BLANK
              }
              onChange={(e) => setIdmetricVersion(e?.target?.value)}
              onBlur={onBlurInput}
              variant="filled"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>{CLOSE}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Header;
