import { IUserSettings } from 'common/models';
import React from 'react';
const AppContext = React.createContext({
  formKey: null,
  idMetricVersion: '4.7.0',
  addressVerification: false,
  documentVerification: false,
  registerAccountWithSSI: false,
  saveSettings: () => {}
} as IUserSettings);

export default AppContext;
