enum Routes {
  DEFAULT = '/',
  LOGIN = '/login',
  SIGNUP = '/signup',
  REJECT = '/reject.html',
  REVIEW = '/review.html',
  WELCOME = '/welcome.html',
  PROFILE = '/profile'
}

export { Routes };
