import profile1 from 'common/assets/profile1.png';
import Name from 'common/assets/Name.png';
import Account from 'common/assets/Account.png';
import Phone from 'common/assets/Phone.png';
import Mail from 'common/assets/Mail.png';
import Location from 'common/assets/Location.png';
import './profile.scss';

const Profile = ({ userProfile }: { userProfile: any }) => {
  return (
    <div className="logged-profile-container">
      <div className="profile-container-layout">
        <div className="profile-header">
          <div className="profile-picture">
            <img src={profile1} alt="Profile Picture" />
          </div>
          <div className="login-vc-success-header">
            <span className="login-vc-success-header-text">
              <h1 className="text-center text-md-left middle-text">
                Welcome to AcmeBank!
              </h1>
            </span>
            <span className="login-vc-success-header-sub-text">{`Hi ${userProfile?.first_name || ''}! You are now logged in.`}</span>
          </div>
        </div>
        <div className="profile-body">
          <div className="account-details-section">
            <div className="account-details-title">{'Account Details'}</div>
            <hr />
            <div className="account-details-name">
              <img src={Name} alt="NameImage" />
              <span className="details-name">{'Name'}</span>
              <span className="details-name-value">{`${userProfile?.first_name || ''} ${userProfile?.last_name || ''}`}</span>
            </div>
            <div className="account-details-ac-number">
              <img src={Account} alt="AccountImage" />
              <span className="details-name">{'Account Number'}</span>
              <span className="details-name-value">{`${userProfile?.instnt_id || ''}`}</span>
            </div>
          </div>
          <div className="contact-details-section">
            <div className="contact-details-title">{'Contact Details'}</div>
            <hr />
            <div className="contact-details-phone">
              <img src={Phone} alt="PhoneImage" />
              <span className="details-name">{'Phone Number'}</span>
              <span className="details-name-value">{`${userProfile?.phoneNo || '0123-456-789'}`}</span>
            </div>
            <div className="contact-details-email">
              <img src={Mail} alt="EmailImage" />
              <span className="details-name">{'Email Address'}</span>
              <span className="details-name-value">{`${userProfile?.email || 'xyz@dmail.com'}`}</span>
            </div>
            <div className="contact-details-location">
              <img src={Location} alt="LocationImage" />
              <span className="details-name">{'Location'}</span>
              <span className="details-name-value">{`${userProfile?.address || 'xyz wer lane 123 g'}`}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
