import { Link } from 'react-router-dom';
import Apply from '../../common/assets/Apply.png';
import Load from '../../common/assets/Load.png';
import Spend from '../../common/assets/Spend.png';
import Track from '../../common/assets/Track.png';
import Control from '../../common/assets/Control.png';
import MobileImage from '../../common/assets/MobileDynamic.png';
import { Routes } from 'common/enums';

import './homePage.scss';

const HomePage = () => {
  return (
    <div className="home-screen">
      <div className="body-screen">
        <div className="body-content-text">
          <div className="top-text">
            <p>Get AcmeBank.</p>
            <p>Anywhere, Anytime.</p>
          </div>
          <div className="bottom-text">
            <p>
              The AcmeBank account, card, and app work together to help you
              understand and manage your spending.
            </p>
          </div>
          <div className="nav-item">
            <nav className="body-menu-mav">
              <ul className="body-menu">
                <li className="menu-item">
                  <Link className="getStarted" to={Routes.SIGNUP}>
                    Get Started<span className="arrow">&rarr;</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="body-screen-menu-item">
        <div id="icons-grid">
          <div className="hero-cell">
            <img src={Apply} alt="APPLY" />
            <div className="hero-cell-text">
              <p>Apply</p>
              <p>Build your money future.</p>
            </div>
          </div>
          <div className="hero-cell">
            <img src={Load} alt="LOAD" />
            <div className="hero-cell-text">
              <p>Load</p>
              <p>No Card fees.</p>
            </div>
          </div>
          <div className="hero-cell">
            <img src={Spend} alt="SPEND" />
            <div className="hero-cell-text">
              <p>Spend</p>
              <p>Build your money future.</p>
            </div>
          </div>
          <div className="hero-cell">
            <img src={Track} alt="TRACK" />
            <div className="hero-cell-text">
              <p>Track</p>
              <p>Boost your savings.</p>
            </div>
          </div>
          <div className="hero-cell">
            <img src={Control} alt="CONTROL" />
            <div className="hero-cell-text">
              <p>Control</p>
              <p>Have fun saving money.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="body-screen-image">
        <div className="image-cell">
          <img src={MobileImage} alt="DynamicImage" />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
