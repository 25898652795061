import search from 'common/assets/magnifying-glass-chart-solid.png';
// import VerifiableCredentialInvitation from 'common/components/vc-invitation';
import './review.scss';

const Review = ({
  transactionId,
  apiUrl,
  isSSIEnable
}: {
  transactionId?: string | null;
  apiUrl: string | null;
  isSSIEnable: any;
}) => {
  return (
    <>
      <div className="feature-wrapper-review">
        <div className={'content'}>
          <span className="search-image">
            <img src={search} alt="review" />
          </span>
          <h2 className="text">Your request is under review.</h2>
          <p className="sub-text">
            Expect an email from us shortly with next steps.
          </p>
          {/* {isSSIEnable && (
            <VerifiableCredentialInvitation
              invitationType="issuer"
              apiUrl={apiUrl}
              transactionId={transactionId}
              customText={null}
              invitation_url={null}
            />
          )} */}
        </div>
      </div>
    </>
  );
};

export default Review;
