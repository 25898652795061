import Error from '../../common/assets/Error.png';
import './reject.scss';

const Reject = ({
  transactionId,
  apiUrl
}: {
  transactionId: string | null | undefined;
  apiUrl: string | null;
}) => {
  return (
    <>
      <div className="feature-wrapper-reject">
        <div className="content">
          <span className="error-image">
            <img src={Error} alt="error" />
          </span>
          <h2 className="text-danger">
            We are unable to approve your application at this time.
          </h2>
          <p className="sub-text-danger">
            Please don't hesitate to try again after 30 days.
            <br />
            And reach out to us if you have any questions.
          </p>
          <div
            className="text-center text-md-left contact-button content-center"
            style={{ marginTop: '10px' }}
          >
            <a href="/" className="btn btn-contact">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reject;
