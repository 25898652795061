import React from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  id: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(000) 000-0000"
        definitions={{
          '#': /[1-9]/
        }}
        inputRef={ref}
        onAccept={(value: any) => {
          onChange({ target: { name: props.id, value } });
        }}
      />
    );
  }
);

export default TextMaskCustom;
