import { useState, useContext, useEffect, useRef } from 'react';
import { Routes } from 'common/enums';
import { Link, useLocation } from 'react-router-dom';
import { fallDown as Menu } from 'react-burger-menu';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogTitle from '@mui/material/DialogTitle';
import AppContext from 'components/AppContext';
//import "./styles.css";
//import './headerStyles.scss';

export const BLANK = '';
export const SETTINGS = 'Settings';
export const CLOSE = 'Close';
export const WORKFLOW_SETTINGS = 'Workflow Settings';
export const WORKFLOW_ID = 'Workflow ID';
export const WORKFLOW_ID_ERROR = `${WORKFLOW_ID} is required`;
export const ID_METRICS_VERSION = 'ID Metrics version';
export const ID_METRICS_VERSION_ERROR = `${ID_METRICS_VERSION} is required`;
export const ADDRESS_VERIFICATION_IS_ENABLED =
  'Address verification is enabled';
export const ADDRESS_VERIFICATION_IS_DISABLED =
  'Address verification is disabled';
export const DOCUMENT_VERIFICATION_IS_ENABLED =
  'Document verification is enabled';
export const DOCUMENT_VERIFICATION_IS_DISABLED =
  'Document verification is disabled';
export const REGISTER_ACCOUNT_WITH_SSI_IS_DISABLED =
  'Register account with SSI is disabled';
export const REGISTER_ACCOUNT_WITH_SSI_IS_ENABLED =
  'Register account with SSI is enabled';
export const USERNAME_ERROR = 'Username is required';
export const PASSWORD_ERROR = 'Password is required';
export const LOGIN = 'Login';
export const VC_CUSTOM_TEXT =
  'Scan with your wallet to login with your verifiable credential';

const Header = () => {
  const [closed, setClosed] = useState(true);
  const myContext = useContext(AppContext);
  const configGroup = {
    workflowId: myContext?.formKey,
    idmetricVersion: myContext?.idMetricVersion,
    addressVerification: myContext?.addressVerification,
    documentVerification: myContext?.documentVerification,
    registerAccountWithSSI: myContext?.registerAccountWithSSI,
    saveSettings: myContext.saveSettings
  };
  return (
    <header className="header right">
      <div className="header__container">
        <div className="logo_container">
          <h1 className="header__logo">
            <Link to={Routes.DEFAULT}>AcmeBank</Link>
          </h1>
        </div>
        <div>
          <nav className="top-menu-nav">
            <ul className="top-menu">
              <li className="menu-item">
                <Link to={Routes.DEFAULT}>About Us</Link>
              </li>
              <li className="menu-item">
                <Link to={Routes.DEFAULT}>FAQs</Link>
              </li>
              <li className="menu-item">
                <Link className="login" to={Routes.LOGIN}>
                  Login
                </Link>
              </li>
              <li className="menu-item">
                <Link className="signup" to={Routes.SIGNUP}>
                  Sign Up
                </Link>
              </li>
              <li className="menu-item">
                {OpenSettingScreen(configGroup, false)}
              </li>
            </ul>
          </nav>

          <Menu
            className="et_mobile_menu"
            outerContainerId={'main-container'}
            isOpen={!closed}
            onOpen={() => setClosed(false)}
          >
            <Link to={Routes.DEFAULT} onClick={() => setClosed(true)}>
              About Us
            </Link>
            <Link to={Routes.DEFAULT} onClick={() => setClosed(true)}>
              FAQs
            </Link>
            <Link to={Routes.LOGIN} onClick={() => setClosed(true)}>
              Login
            </Link>
            <Link to={Routes.SIGNUP} onClick={() => setClosed(true)}>
              Sign Up
            </Link>
            <Link to={''} onClick={() => setClosed(true)}>
              {OpenSettingScreen(configGroup, true)}
            </Link>
          </Menu>
        </div>
      </div>
    </header>
  );
};

const OpenSettingScreen = (
  configGroup: {
    workflowId: any;
    idmetricVersion: any;
    addressVerification: any;
    documentVerification: any;
    registerAccountWithSSI: any;
    saveSettings: any;
  },
  isMobile: boolean
) => {
  const [open, setOpen] = useState(false);
  const [workflowId, setWorkflowId] = useState(configGroup.workflowId);
  const [idmetricVersion, setIdmetricVersion] = useState(
    configGroup.idmetricVersion
  );
  const [addressVerification, setAddressVerification] = useState(
    configGroup.addressVerification
  );
  const [documentVerification, setDocumentVerification] = useState(
    configGroup.documentVerification
  );
  const [registerAccountWithSSI, setRegisterAccountWithSSI] = useState(
    configGroup.registerAccountWithSSI
  );
  const _location = useLocation();

  useEffect(() => {
    setWorkflowId(configGroup.workflowId);
    setIdmetricVersion(configGroup.idmetricVersion);
    setAddressVerification(configGroup.addressVerification);
    setDocumentVerification(configGroup.documentVerification);
    setRegisterAccountWithSSI(configGroup.registerAccountWithSSI);
  }, [
    configGroup.workflowId,
    configGroup.idmetricVersion,
    configGroup.registerAccountWithSSI,
    configGroup.addressVerification,
    configGroup.documentVerification
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    configGroup?.saveSettings({
      workflowId,
      idmetricVersion,
      addressVerification,
      documentVerification,
      registerAccountWithSSI
    });
    setOpen(false);
  };

  return (
    <div className="workflow-setting-screen">
      <div className="setting" onClick={() => handleClickOpen()}>
        {!isMobile ? (
          _location?.pathname === Routes.DEFAULT ? (
            <SettingsApplicationsIcon />
          ) : (
            ''
          )
        ) : (
          SETTINGS
        )}
      </div>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        className={'workflow-setting-screen-description'}
      >
        <DialogTitle>{WORKFLOW_SETTINGS}</DialogTitle>
        <DialogContent>
          <div className="text-margin">
            <TextField
              required
              id="filled-required"
              label={WORKFLOW_ID}
              value={workflowId}
              error={workflowId === BLANK}
              helperText={workflowId === BLANK ? WORKFLOW_ID_ERROR : BLANK}
              onChange={(e) => setWorkflowId(e?.target?.value)}
              variant="filled"
            />
          </div>
          <div className="text-margin">
            <TextField
              required
              id="filled-required"
              label={ID_METRICS_VERSION}
              value={idmetricVersion}
              error={idmetricVersion === BLANK}
              helperText={
                idmetricVersion === BLANK ? ID_METRICS_VERSION_ERROR : BLANK
              }
              onChange={(e) => setIdmetricVersion(e?.target?.value)}
              variant="filled"
            />
          </div>

          {/* <div className="text-margin">
        <FormGroup>
            <FormControlLabel 
                control={<Switch 
                            checked = { documentVerification}  
                            onChange={(e)=> setDocumentVerification(e?.target?.checked)} 
                          />} 
                label={documentVerification ? DOCUMENT_VERIFICATION_IS_ENABLED : DOCUMENT_VERIFICATION_IS_DISABLED} />
          </FormGroup>  
        </div> */}
          {/* <div className="text-margin">
        <FormGroup>
            <FormControlLabel 
                control={<Switch 
                            checked = { registerAccountWithSSI}  
                            onChange={(e)=> setRegisterAccountWithSSI(e?.target?.checked)} 
                          />} 
                label={registerAccountWithSSI ? REGISTER_ACCOUNT_WITH_SSI_IS_ENABLED : REGISTER_ACCOUNT_WITH_SSI_IS_DISABLED} />
          </FormGroup>  
        </div> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>{CLOSE}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Header;
